import { loadStripe, Stripe, StripeElementLocale } from '@stripe/stripe-js'
import { config } from '@/conf'
import { XHR } from '@/utils/xhr'
import { localstore } from '@/utils/localstorage'

const trial_pricing_dev = {"ron":300,"cop":520000,"dkk":375,"egp":3300,"ils":300,"nzd":150,"brl":900,"aed":299,"czk":1250,"gbp":90,"nok":600,"mxn":1900,"sgd":90,"cad":100,"eur2":50,"eur":50,"aud":90,"try":2100,"qar":400,"huf":20500,"pln":400,"zar":2900,"chf":50,"sek":600,"pen":500,"uah":3900,"krw":1500,"usd":99,"ars":50000,"thb":2000,"clp":855,"myr":300,"sar":300,"jpy":150}

const sub_pricing_dev = {"sgd":3900,"gbp":1990,"cop":9750000,"sar":12000,"pen":7900,"ron":18000,"usd":3200,"aud":6500,"eur":1950,"pln":14000,"aed":15100,"nzd":7000,"dkk":26000,"chf":3500,"ars":390000,"uah":117000,"jpy":5500,"clp":24150,"thb":110200,"brl":10600,"krw":42000,"eur2":3999,"egp":97400,"myr":9400,"nok":45000,"qar":11500,"ils":15000,"huf":1197000,"try":60200,"czk":72956,"sek":32900,"zar":37400,"cad":4500,"mxn":38000}

class Checkout {
  spk!: string
  trial_pricing!: {[key: string]: number}
  sub_pricing!: {[key: string]: number}
  stripe!: Stripe

  // checkout initializer
  constructor () {
    // set pre-defined pricing (for dev)
    this.trial_pricing = trial_pricing_dev
    this.sub_pricing  = sub_pricing_dev
    this.spk = 'pk_live_51NkMmMIoKUvpYI4XEwSSjBacuNH2FX4sN7aGUqM7DHLfLSJNyekfdhVuG6HDHsPLVgYV8zv5BxNAA40jHdkPlYFB00b8AJmWFS'

    /*
    // remove euro if current currency is eu2
    const current_currency = localstore.get('currency')
    if (current_currency && current_currency === 'eur2') {
      delete currency_formats['eur']
      delete currency_formats['eur3']
    } else if (current_currency && current_currency === 'eur') {
      delete currency_formats['eur3']
      delete currency_formats['eur2']
    } else if (current_currency && current_currency === 'usd') {
      delete currency_formats['usd2']
      delete currency_formats['usd3']
    } else if (current_currency && current_currency === 'usd2') {
      delete currency_formats['usd']
      delete currency_formats['usd3']
    } else if (current_currency && current_currency === 'usd3') {
      delete currency_formats['usd']
      delete currency_formats['usd2']
    } else if (current_currency && current_currency === 'cad2') {
      delete currency_formats['cad']
    }else if (current_currency && current_currency === 'aud2') {
      delete currency_formats['aud']
    }
     */

    /*
    // init xhr
    const xhr = new XHR()
    // fetch stripe public key and pricing from server
    xhr.get('/stripe').then(r => {
      const resp_data = r.decode<StripeInfo>()
      if (!resp_data) { console.error('failed to get stripe info'); return }
      // set pub key
      this.spk = resp_data!.spk
      // set updated pricing
      // this.trial_pricing = resp_data!.trial_pricing
      // this.sub_pricing = resp_data!.sub_pricing

      // remove euro if current currency is eu2
      const current_currency = localstore.get('currency')
      if (current_currency && current_currency === 'eur2') {
        delete currency_formats['eur']
      }

    }).catch(e => {
      console.error("failed to get stripe public key")
    })

     */
  }

  // initializes stripe module
  init () {
    // @ts-ignore
    loadStripe(this.spk).then((stripe: Stripe) => {
      this.stripe = stripe
    })
  }

  // launch checkout process
  launch (props: CheckoutConfig) {
    if (!check_card()) {
      return false
    }

    // init stripe elements
    const elements = this.stripe.elements({
      clientSecret: props.token,
      locale: (config.lang.iso === 'latam' ? 'es-419' : config.lang.iso) as StripeElementLocale
    })

    // create and mount stripe element
    const payment_el = elements.create('payment', { terms: {card: 'never'} })
    payment_el.mount('#payment-element')

    // on payment element 'ready' event. element is rendered and mounted
    payment_el.on('ready', () => {
      // fire on_ready handler
      props.on_ready()

      // submit event listener
      const form = document.getElementById('payment-form')!
      form.addEventListener('submit', async (event) => {
        event.preventDefault()

        // fire on_busy handler
        props.on_busy(true)

        // confirm setup intent
        const { error } = await this.stripe.confirmPayment({
          elements, confirmParams: {
            return_url: checkout_complete_url(props.uid),
            // billing_details: {
            //  name: nameInput.value,
            // },
          }
        })

        // immediate error (invalid card data ect.)
        if (error) {
          // ******-----
          // console.log(error)
          // ------*****

          // card declined
          if (error.type == 'card_error') {
            // get card check num and increment
            let n = check_card_n(); n ++
            localstore.set('XNpD5J8X', n+'NpD5J8X')
          }
          // fire on_error handler
          props.on_error(error.message!)
          // fire on_busy handler
          props.on_busy(false)
        } else {
          // customer will be redirected to an intermediate site to authorize the payment,
          // then redirected to the `return_url`

          // fire on_busy handler
          props.on_busy(false)
        }
      })
    })

    // fire on_change event when user focus on element (to hide any previous errors)
    payment_el.on('focus', (event) => {
      props.on_change()
    })

    payment_el.on('change', (event) => {
      console.log('payment element change event', event)
    })

    return true
  }
}

// checks card status
const check_card = () => {
  const n = check_card_n()
  // error
  if (n === -1) {
    return false
  }
  // no data
  else if (n === 0) {
    return true
  }
  // limit reached
  else if (n > 1) {
    return false
  }
  return true
}
const check_card_n = () => {
  const data = localstore.get('XNpD5J8X')
  if (data) {
    const count = data.substring(0,1)
    const num = parseInt(count);
    if (!num) {
      console.error('check_card NaN')
      return -1
    }
    return num
  }
  return 0
}

// checkout configuration
interface CheckoutConfig {
  uid       : string
  token     : string
  on_ready  (): void
  on_busy   (_:boolean): void
  on_error  (_:string): void
  on_change (): void
}

// returns the url where the user is directed after completing checkout
const checkout_complete_url = (uid: string) : string => {
  // dev mode
  if (process.env.NODE_ENV === 'development') {
    console.log("checkout configured in dev mode [endpoint: http://127.0.0.1:4444]")
    return "http://127.0.0.1:4444/checkout?uid="+uid+"&lang="+config.lang.iso
  }

  // append user language iso2 code if the language is set in url (forced)
  if (config.lang.in_url) {
    // url with app domain name defined in config, user id and user language
    return "https://"+config.host+"/checkout?uid="+uid+"&lang="+config.lang.iso
  }

  // url with app domain name defined in config
  return "https://"+config.host+"/checkout?uid="+uid
}

// global checkout class
let checkout: Checkout
const InitCheckout = () => {
  checkout = new Checkout()
}

// stripe pub key and pricing
interface StripeInfo {
  spk           : string
  trial_pricing : {[key: string]: number}
  sub_pricing   : {[key: string]: number}
}

/*
  "brl": 10600,
  "pen": 7900,
  "ars": 390000,
  "clp": 16200,
  "egp": 62500,
  "try": 38500,
  "cop": 9750000,
  "mxn": 38000,
 */

// currency symbols
const currency_formats: {[key: string]: string} = {
  "eur": "€{n}",
  "eur2": "€{n}",
  "gbp": "£{n}",
  "krw": "₩{n}",
  "sar": "{n} SR",
  "aed": "{n}د.إ",
  "dkk": "{n} kr",
  "czk": "{n} Kč",
  "sek": "{n} kr",
  "huf": "{n} Ft",
  "jpy": "¥{n}",
  "usd": "${n}",
  // "usd2": "${n}",
  // "usd3": "${n}",
  "aud": "${n}",
 //  "aud2": "${n}",
  "sgd": "${n}",
  "ron": "{n} lei",
  "cad": "${n}",
  // "cad2": "${n}",
  "pln": "{n}zł",
  "nok": "{n} kr",
  "nzd": "${n}",
  "chf": "{n} Fr",
  "ils": "₪{n}",
  "qar": "QR {n}",
  "brl": "R${n}",
  "pen": "S/{n}",
  "ars": "$ {n}",
  "clp": "$ {n}",
  "egp": "£ {n}",
  "try": "₺{n}",
  "cop": "$ {n}",
  "mxn": "$ {n}",
  "uah": "₴{n}",
  "zar": "R{n}",
  "thb": "฿{n}",
  "myr": "RM{n}"
}

const no_cent = ['jpy', 'clp', 'krw']

const trial_price = () => {
  // get currency from session storage
  const currency = localstore.get('currency') || 'eur'
  // get amount int
  let amount = checkout.trial_pricing[currency]
  if (!no_cent.includes(currency)) {
    amount = amount/100
  }
  // return formatted price
  return currency_formats[currency].replace('{n}', amount.toFixed(2))
}
const subscription_price = () => {
  // get currency from session storage
  const currency = localstore.get('currency') || 'eur'
  // get amount int
  let amount = checkout.sub_pricing[currency]
  if (!no_cent.includes(currency)) {
    amount = amount/100
  }
  // return formatted price
  return currency_formats[currency].replace('{n}', amount.toFixed(2))
}

export { checkout, CheckoutConfig, InitCheckout, trial_price, subscription_price, currency_formats }
