<script setup lang="ts">
import Modal from '@/components/main/modal.vue'
import Navbar from '@/components/main/navbar.vue'
import AppFooter from '@/components/main/footer.vue'
import { session, localstore } from '@/utils/localstorage'
import { currency_formats, InitCheckout } from '@/utils/checkout'
import { onMounted} from 'vue'
import { config } from '@/conf'

// get url arguments
const args = new URLSearchParams(window.location.search)

// get gclid and save if defined
const gclid = args.get('gclid')
if (gclid) {
  session.set('gclid', gclid)
}

// get trial only arg
const trial_only = args.get('t')
if (trial_only) {
  localstore.set('t', 1)
}

// get currency from args
let currency = args.get('c')
// get currency from local storage if args is undefined
if (!currency) {
  currency = localstore.get('currency') || 'eur'
}
// force lowercase
currency = currency!.toLowerCase()
// if currency not supported, set to euro
if (!currency_formats[currency]) {
  currency = 'eur'
}
// save to local storage
localstore.set('currency', currency)

// if (config.lang.iso === 'ar' || config.lang.iso === 'he') {
if (config.lang.iso === 'he') {
  document.body.classList.add('rtl')
}

onMounted(() => {
  // start checkout util
  InitCheckout()
})

</script>

<!-- template markdown -->
<template>
  <navbar/>
  <router-view/>
  <app-footer/>
  <modal/>
</template>

